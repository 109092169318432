import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import Arrow from '../../assets/icons/arrow.svg';
import FlexBox from '../Atoms/FlexBox';

const StyledPaginationWrapper = styled.nav`
  .pagination-previous {
    order: 0
  }
`;

function RollPagination(props) {
  const { last, goToPage } = props;

  return (
    <StyledPaginationWrapper
      className="pagination is-small"
      role="navigation"
      aria-label="pagination"
    >
      <ReactPaginate
        previousLabel={(
          <FlexBox width={24} height={24} style={{ transform: 'rotate(-180deg)' }}>
            <Arrow />
          </FlexBox>
        )}
        nextLabel={(
          <FlexBox width={24} height={24}>
            <Arrow />
          </FlexBox>
        )}
        breakLabel="..."
        breakClassName="pagination-ellipsis"
        pageCount={last}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => {
          goToPage(selected + 1);
        }}
        containerClassName="pagination-list"
        activeLinkClassName="is-current"
        previousClassName="pagination-previous"
        nextClassName="pagination-next"
        pageLinkClassName="pagination-link"
      />
    </StyledPaginationWrapper>
  );
}

export default RollPagination;
