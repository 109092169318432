import React, { Component } from 'react';

import Section from '../Atoms/Section';
import theme from '../../styles/theme';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';
import { formatTypeString } from '../../utilis/helpers';

class RollHeader extends Component {
  render() {
    const { title = 'Articles', subtitle = 'All' } = this.props;

    return (
      <Section pt={8} pb={6} background={`linear-gradient(to left, #bddbff, #78a2d3)`}>
        <Container>
          <Row>
            <Column>
              <Heading h={4} color={theme.color.white}>
                {formatTypeString(subtitle)}
              </Heading>
              <Heading h={1} color={theme.color.white}>
                {formatTypeString(title)}
              </Heading>
            </Column>
          </Row>
          <Row />
        </Container>
      </Section>
    );
  }
}

export default RollHeader;
