import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { graphql, StaticQuery } from 'gatsby';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import Heading from '../Atoms/Heading';
import Box from '../Atoms/Box';
import Paragraph from '../Atoms/Paragraph';
import CustomLink from '../Atoms/CustomLink';

import { buildArticlePath, formatTypeString } from '../../utilis/helpers';
import { pageUrls } from '../../config/pageSetting';

@observer
class RollMenuUI extends Component {
  @observable type = [];

  componentDidMount() {
    const { edges: posts } = this.props.data.allMarkdownRemark;

    let t = [];
    posts.forEach(edge => {
      if (get(edge, 'node.frontmatter.type') && get(edge, 'node.frontmatter.group')) {
        t = t.concat(buildArticlePath(edge.node.frontmatter));
      }
    });
    this.type = sortBy(uniq(t), val => val);
  }

  render() {
    const { currGroup } = this.props;

    return (
      <Box>
        <Heading h={3} pb={4}>
          {formatTypeString(currGroup)}
        </Heading>
        <CustomLink to={pageUrls.articles.url}>
          <Paragraph>
            <FormattedMessage id="roll:menu.all" defaultMessage="All" />
          </Paragraph>
        </CustomLink>
        {this.type.map(type => (
          <CustomLink to={`${pageUrls.articles.url}/${type}`}>
            <Paragraph>{formatTypeString(type)}</Paragraph>
          </CustomLink>
        ))}
      </Box>
    );
  }
}

const RollMenu = ({currGroup}) => (
  <StaticQuery
    query={graphql`
      query RollMenuQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: { templateKey: { eq: "article-post" } }
          }
        ) {
          totalCount
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                group
                type
              }
            }
          }
        }
      }
    `}
    render={data => <RollMenuUI data={data} currGroup={currGroup}/>}
  />
);

export default RollMenu;
