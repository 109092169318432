import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import truncate from 'lodash/truncate';

import styled from 'styled-components';
import Box from '../../Atoms/Box';
import FlexBox from '../../Atoms/FlexBox';
import Paragraph from '../../Atoms/Paragraph';
import CustomLink from '../../Atoms/CustomLink';
import theme from '../../../styles/theme';
import Heading from '../../Atoms/Heading';
import { buildArticlePath, formatTypeString } from '../../../utilis/helpers';

const StyledHeading = styled(Heading)`
  transition: color 100ms ease-in-out;
  color: ${props => props.theme.color.black};
  &:hover {
    color: ${props => props.theme.color.blue};
  }
`;

class ItemWithDate extends PureComponent {
  render() {
    const {
      title, date, type, excerpt, ...rest
    } = this.props;
    const [year, month, day] = date.split('-');

    return (
      <FlexBox
        borderBottom={`1px solid ${theme.color.midGray}`}
        width="100%"
        height={['auto', 'auto', 'auto', 'auto', 220]}
        py={3}
        alignItems="flex-start"
        {...rest}
      >
        <Box
          display={['none', 'none', 'block']}
          width={[100, 100, 100, 128]}
          color={theme.color.blue}
          textAlign="center"
          pr={3}
          pt={3}
          alignSelf="flex-start"
        >
          <Box letterSpacing={-1.2} lineHeight={1} fontSize={[4, 4, 4, 5]}>
            {month}
          </Box>
          <Box letterSpacing={-1.7} lineHeight={1.5} fontSize={[5, 5, 5, 6]} fontWeight={700}>
            {day}
          </Box>
          <Box letterSpacing={-2.3} lineHeight={1} fontSize={[4, 4, 4, 5]}>
            {year}
          </Box>
        </Box>
        <FlexBox flex={1} height="100%" flexDirection="column" justifyContent="flex-start">
          <FlexBox justifyContent="flex-start" width="100%">
            <Heading h={4} pb={1} color={theme.color.blue} width="50%">
              {formatTypeString(type)}
            </Heading>
            <Paragraph fontSize={1} color={theme.color.greyish} display={['block', 'block', 'none']} ml="auto">
              {day} {month} {year}
            </Paragraph>
          </FlexBox>
          <CustomLink to={buildArticlePath(this.props)} width="100%">
            <StyledHeading h={3}>{truncate(title, { length: 90 })}</StyledHeading>
          </CustomLink>
          <Box flex={1} width="100%" position="relative" overflowHidden mb={2}>
            <Paragraph fontSize={1} position="absolute" width="100%">
              {excerpt}
            </Paragraph>
          </Box>
          <Box width="100%">
            <CustomLink to={buildArticlePath(this.props)} fontSize={1}>
              <FormattedMessage id="button:readon" defaultMessage="Read on" />
            </CustomLink>
          </Box>
        </FlexBox>
      </FlexBox>
    );
  }
}

export default ItemWithDate;
