import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import ceil from 'lodash/ceil';

import { FormattedMessage } from 'react-intl';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import RollPagination from './RollPagination';
import ItemWithDate from './Items/ItemWithDate';
import Heading from '../Atoms/Heading';

@observer
class DetailedRoll extends React.Component {
  @observable limit = 5;

  @observable currPage = 1;

  @observable lastPage = 1;

  @action
  goToPage = (page) => {
    if (typeof page !== 'number' || page < 1 || page > this.lastPage) {
      return;
    }
    this.currPage = page;
  };

  componentDidMount() {
    const { totalCount, edges: posts } = this.props;

    this.lastPage = ceil(totalCount / this.limit);
  }

  render() {
    const { edges: posts } = this.props;

    const start = this.limit * (this.currPage - 1);
    const end = start + this.limit - 1;

    const currArr = posts.filter((val, index) => index >= start && index <= end);

    return (
      <>
        <Row multi>
          <Column col="is-12">
            <Heading h={2} pb={0}>
              <FormattedMessage id="news:roll.title" defaultMessage="News" />
            </Heading>
            {currArr && currArr.map(({ node: post }) => (
              <ItemWithDate
                key={post.id}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                group={post.frontmatter.group}
                type={post.frontmatter.type}
                slug={post.fields.slug}
                excerpt={post.excerpt}
              />
            ))}
          </Column>
        </Row>
        <Row>
          <Column>
            <RollPagination curr={this.currPage} last={this.lastPage} goToPage={this.goToPage} />
          </Column>
        </Row>
      </>
    );
  }
}

export default DetailedRoll;
