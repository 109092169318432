import React from 'react';
import { Link, graphql } from 'gatsby';

import RollHeader from '../components/Rolls/RollHeader';
import Section from '../components/Atoms/Section';
import Container from '../components/Atoms/Container';
import Row from '../components/Atoms/Row';
import Column from '../components/Atoms/Column';
import RollMenu from '../components/Rolls/RollMenu';
import DetailedRoll from '../components/Rolls/DetailedRoll';
import theme from '../styles/theme';

// ********************************* UI Template (Shared with CMS) *********************************
export const ArticleRollTemplate = ({
  group, type, lang, totalCount, edges,
}) => (
  <>
    <RollHeader subtitle={group} title={type} />
    <Section pt={3}>
      <Container>
        <Row>
          <Column
            col="is-2"
            borderRight={`1px solid ${theme.color.midGray}`}
            display={['none', 'none', 'block']}
          >
            <RollMenu currGroup={group} />
          </Column>
          <Column col="is-10">
            <DetailedRoll totalCount={totalCount} edges={edges} />
          </Column>
        </Row>
      </Container>
    </Section>
  </>
);

// ********************************* Render page *********************************
const ArticleRollPage = ({ pageContext, data }) => <ArticleRollTemplate {...pageContext} {...data.allMarkdownRemark} />;

export default ArticleRollPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query ArticleRoll($group: String!, $type: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-post" }
          group: { eq: $group }
          type: { eq: $type }
        }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            type
            group
            title
            date(formatString: "YYYY-MMMM-DD")
          }
        }
      }
    }
  }
`;
